import { supabase } from "@/supabase.js"

export default {
  methods: {
    saveSprite() {
      const formatedSbx = "sbx-" + this.name

      const compressedSprite = {
        frames: [...this.clearEmptyFramesAndLayers(this.unhashHexKeys(this.editorSprite))],
        name: formatedSbx,
        size: {
          rows: this.size.rows,
          cols: this.size.cols,
        }
      }
      const spriteToSave = {
        frames: [...this.clearEmptyFramesAndLayers(this.editorSprite)],
        name: formatedSbx,
        size: {
          rows: this.size.rows,
          cols: this.size.cols,
        }
      }
      if (!window.localStorage.getItem([formatedSbx])) {
        const data = JSON.stringify(spriteToSave)

        var spriteArray = []
        spriteArray.push(data)

        window.localStorage.setItem([formatedSbx], spriteArray)
      }

      this.$toast(
        `spritebooth® : sprite "${formatedSbx}" has been saved to local storage.`,
        {
          timeout: 8000,
          icon: 'fas fa-circle-user',
        }
      )
    },
    saveVersion() {
      let date = new Date()

      this.version.unshift({
        frames: this.editorSprite,
        size: this.size,
        time: date.toUTCString()
      })
    },
    updateVersion() {
      if (_.isEqual(this.editorSprite, this.version[0].frames)) {
        this.saveVersion()
      }
      else {
        return
      }
    },
    goBackOneVersion() {
      // if(this.versionIndex == this.version.length-1){
      //   this.versionIndex = this.versionIndex
      // }
      // else{
      //   this.versionIndex++
      // }
      this.version.push(this.version.shift())

      this.editorSprite = _.cloneDeep(this.version[this.versionIndex].frames)
      this.size = _.cloneDeep(this.version[0].size)
      this.editorPalette = _.cloneDeep(this.spritePalette)

      // this.$forceUpdate()
      this.resetFrame()
      this.applyColorsOfFrame()
      this.createLayersPreview()

    },
    goFowardOneVersion() {
      this.version.unshift(this.version.pop())

      this.editorSprite = _.cloneDeep(this.version[0].frames)
      this.size = _.cloneDeep(this.version[0].size)
      this.editorPalette = _.cloneDeep(this.spritePalette)

      this.resetFrame()
      this.applyColorsOfFrame()
      this.createLayersPreview()
    },
    isoToValue(arg) {
      return arg.slice(0, arg.indexOf('T')).split('-').join('') + '-' + arg.slice(arg.indexOf('T') + 1, arg.indexOf('.') + 4).replace('.', '').split(':').join('')
    },
    userIsLogged(){
      if(this.$store.getters.AuthUser && this.$store.getters.AuthUser.id)
      {
        return true
      }
      else {
        return false
      }
    },

    ////////////////////// Save To DB ////////////////////////
    async publish() {
      if(this.userIsLogged()){
        
        if (this.unique_key && this.id) { // if sprite already exists
          let dataToSave = {
            frames: JSON.stringify([...this.arryfyFrames(this.clearEmptyFramesAndLayers(this.editorSprite))]),

            name: this.name,
            public: this.public || true,
            author: this.$store.getters.AuthUser.id,
            unique_key: this.unique_key,
            id: this.id,
            
            urls_allowed: this.urls_allowed || null,
            forked_from: this.forked_from || null,


            background: this.background,
            size: JSON.stringify({
              cols: this.size.cols,
              rows: this.size.rows
            }),
          }
          try {
            const { data, error } = await supabase
              .from('sprites')
              .upsert({
                id: this.id,
                ...dataToSave
              })
            if (data) {
              this.$toast(
                `sprite ${this.unique_key}, '${this.name}' sucessfully updated.`,
                {
                  timeout: 8000,
                  icon: 'fas fa-file-arrow-up',
                }
              )
            }
            else if(error){
              // this.$toast(
              //   `${error}`,
              //   {
              //     timeout: 6000,
              //     icon: 'fas fa-file-arrow-up',
              //     hideProgressBar: true
              //   }
              // )
              console.log(error)
            }

          }
          catch (e) {
            this.$toast(
              `${e.description}, ${e.message}`,
              {
                timeout: 8000,
                icon: 'fas fa-file-arrow-up',
              }
            )
          }
        }
        else { // if sprite does NOT exist

          let dataToSave = {
            frames: JSON.stringify([...this.arryfyFrames(this.clearEmptyFramesAndLayers(this.editorSprite))]),

            name: this.name,
            public: this.public || true,

            urls_allowed: this.urls_allowed || null,
            forked_from: this.forked_from || null,
            // map_layer_name: this.map_layer_name || [],

            author: this.$store.getters.AuthUser.id,

            background: this.background,
            size: JSON.stringify({
              cols: this.size.cols,
              rows: this.size.rows
            }),
          }
          try {
            const { data, error } = await supabase
              .from('sprites')
              .insert([
                dataToSave
              ])
            if (data) {
              this.$toast(
                `Artwork '${this.name}' sucessfully published.`,
                {
                  timeout: 8000,
                  icon: 'fas fa-file-arrow-up',
                }
              )
            }

          }
          catch (e) {
            this.$toast(
              `${e.description}, ${e.message}`,
              {
                timeout: 8000,
                icon: 'fas fa-file-arrow-up',
              }
            )
          }

        }
      }
      else {
        this.$toast(
          `You must be logged in to be able to save`,
          {
            timeout: 8000,
            icon: 'fas fa-circle-user',
          }
        )
      }
    },
    
    /* ------------------------------ DUPLICATE / FORK ------------------ */
    async fork() {
      if(this.userIsLogged()){
        if(this.$store.getters.AuthUser.id == this.author){
          
          let dataToSave = {
            frames: JSON.stringify([...this.arryfyFrames(this.clearEmptyFramesAndLayers(this.editorSprite))]),

            name: `${this.name}_fork`,
            public: this.public || true,
            author: this.$store.getters.AuthUser.id,

            urls_allowed: this.urls_allowed || null,
            forked_from: this.unique_key,
            // map_layer_name: this.map_layer_name || [],

            background: this.background,
            size: JSON.stringify({
              cols: this.size.cols,
              rows: this.size.rows
            }),
          }
          try {
            const { data, error } = await supabase
              .from('sprites')
              .insert([
                dataToSave
              ])
            if (data) {
              this.$toast(
                `Artwork '${data[0].name}' was sucessfully forked from '${this.name}' : ${this.unique_key}.`,
                { timeout: 8000, icon: 'fas fa-file-arrow-up'}
              )
            }

          }
          catch (e) {
            this.$toast(
              `${e.description}, ${e.message}`,
              { timeout: 8000, icon: 'fas fa-file-arrow-up', }
            )
          }
        }
        else {
          this.$toast(
            `You must be the author of the artwork to be able to fork`,
            { timeout: 8000, icon: 'fas fa-circle-user'}
          )
        }

      }
      else {
        this.$toast(
          `You must be logged in to be able to fork`,
          {timeout: 8000,icon: 'fas fa-circle-user',}
        )
      }
    },
    
    ////////////////////// Local Storage Check ////////////////////////
    checkLocalStorage() {
      let keyArr = []
      for (let key in localStorage) {
        if (key.indexOf("sbx") == 0) {
          keyArr.push(key)
        }
      }
      console.log('The following Spritebooth® Sbx files has been found in your local storage : ')
      this.$toast(
        `The following Spritebooth® Sbx files has been found in your local storage : `,
        {timeout: 8000,icon: 'fas fa-file-arrow-up',}
      )
      for (let key of keyArr) {
        this.$toast(
          `The following Spritebooth® Sbx files has been found in your local storage : ${JSON.parse(window.localStorage.getItem(key).name)}`,
          {timeout: 8000,icon: 'fas fa-file-arrow-up',}
        )
      }
    }
  }
}
