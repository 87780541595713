export default {
  methods: {
    checkIfReAddPixel(index, color, evnt) {
      let cols = parseInt(this.size.cols)
      let rows = parseInt(this.size.rows)

      if (Array.isArray(this.editorSprite[this.frameIndex])) {
        let ObjVals = Object.values(this.editorSprite[this.frameIndex][this.layerIndex])

        if (index % cols >= cols || index >= cols * rows || index < 0 || ObjVals
          .reduce(
            (previous, current) => previous.concat(current), []
          )
          .includes(index)) {
          return
        }
        else {
          this.addPixel(index, color, evnt)
        }
      }

      this.checkIfReAddPixel(index - cols, color, evnt)
      this.checkIfReAddPixel(index + 1, color, evnt)
      this.checkIfReAddPixel(index + cols, color, evnt)
      this.checkIfReAddPixel(index - 1, color, evnt)

      return
    },
    handleDraw(index, color, evnt) {
      if (this.fillMode) {
        this.checkIfReAddPixel(index, color, evnt)
      }
      else {
        this.addPixel(index, color, evnt)
      }
    },
    checkTexture(index, color, evnt) {
      let cols = parseInt(this.size.cols)
      let rows = parseInt(this.size.rows)

      if (this.textureMode == 'texture-free') {
        return true
      }
      else if (this.textureMode == 'texture-even') {

        if (cols % 2 == 0) { // cols is even

          if ((this.indexToCoord(index, cols).y - 1) % 2 === 0) { // if row is even
            // console.log ('row is even')
            if (index % 2 === 0) {
              // this.addPixel(index, color, evnt)
              return true
            }
            else {
              return false
            }
          }
          else if ((this.indexToCoord(index, cols).y - 1) % 2 !== 0) { // if row is odd
            // console.log ('row is odd')
            if (index % 2 !== 0) {
              // this.addPixel(index, color, evnt)
              return true
            }
            else {
              return false
            }
          }

        }
        else { // cols is odd

          if (index % 2 == 0) {
            return true
          }
          else {
            return false
          }

        }
      }
      else if (this.textureMode == 'texture-odd') {
        if (cols % 2 == 0) { // cols is even

          if ((this.indexToCoord(index, cols).y - 1) % 2 === 0) { // if row is even
            if (index % 2 !== 0) {
              return true
            }
            else {
              return false
            }
          }
          else if ((this.indexToCoord(index, cols).y - 1) % 2 !== 0) { // if row is odd
            if (index % 2 == 0) {
              return true
            }
            else {
              return false
            }
          }
        }
        else { // cols is odd

          if (index % 2 !== 0) {
            return true
          }
          else {
            return false
          }
        }

      }

    },
    addPixel(index, color, evnt) {

      // if (!this.moveMode && !this.colorPickMode && !this.isPaused) {
        // if (this.drawMode || evnt) {
        //   this.$toast(
        //     `spritebooth® : frames are editable only when sprite is paused.`,
        //     {
        //       timeout: 8000,
        //       icon: 'fas fa-circle-user',
        //     }
        //   )
        // }
      // }
      if (!this.moveMode && !this.colorPickMode && this.isPaused) {
        if (this.drawMode || evnt) {

          this.deletePixelIfColored(index) // to enable delete with alt + click

          if (Array.isArray(this.editorSprite[this.frameIndex])) { // if frame if layered
            // console.log(this.editorSprite[this.frameIndex][this.layerIndex])
            if (this.currentFrame[this.layerIndex].hasOwnProperty(color) == false) {
              if (color !== '') {

                if (this.checkTexture(index, color, evnt)) {

                  Object.assign(this.editorSprite[this.frameIndex][this.layerIndex], { [color]: [index] })

                  if (this.editorPalette.includes(color) == false) {
                    this.editorPalette.push(color)
                  }

                }

              }

            }
            else if (this.currentFrame[this.layerIndex].hasOwnProperty(color) == true) {
              if (color !== '') {

                if (this.checkTexture(index, color, evnt)) {

                  this.editorSprite[this.frameIndex][this.layerIndex][color].push(index)
                  // this.editorPalette = this.spritePalette
                }

              }
            }
          }

          else { // not array
            if (this.editorSprite[this.frameIndex].hasOwnProperty(color) == false) {
              if (color !== '') {

                if (this.checkTexture(index, color, evnt)) {

                  let arr = []
                  arr.push(index)
                  this.editorSprite[this.frameIndex][color] = arr

                  if (this.editorPalette.includes(color) == false) {
                    this.editorPalette.push(color)
                  }
                }

              }
            }
            else if (this.editorSprite[this.frameIndex].hasOwnProperty(color) == true) {
              if (color !== '') {

                if (this.checkTexture(index, color, evnt)) {

                  this.editorSprite[this.frameIndex][color].push(index)
                  // this.editorPalette = this.spritePalette
                }

              }

            }
          }

          this.updateRenders()
        }
      }
    },
    deletePixelIfColored(indexArg) {
      if (this.textureMode == 'texture-free') {

        if (Array.isArray(this.editorSprite[this.frameIndex])) {
          for (let colorOfObj in this.editorSprite[this.frameIndex][this.layerIndex]) {
            // console.log( "layer contains color: "+ colorOfObj, )
            if (this.editorSprite[this.frameIndex][this.layerIndex][colorOfObj].includes(indexArg)) {
              // console.log( "deleting "+ indexArg + " included in " + colorOfObj)
              // console.log(this.editorSprite[this.frameIndex][this.layerIndex][colorOfObj])
              this.editorSprite[this.frameIndex][this.layerIndex][colorOfObj].splice(
                this.editorSprite[this.frameIndex][this.layerIndex][colorOfObj].indexOf(indexArg), 1
              )
              return [true, this.layerIndex, colorOfObj]
            }
          }
        }
        else {
          for (let i = 0; i < Object.values(this.editorSprite[this.frameIndex]).length; i++) {
            for (let j = 0; j < Object.values(this.editorSprite[this.frameIndex])[i].length; j++) {
              if (Object.values(this.editorSprite[this.frameIndex])[i][j] == indexArg) {
                // console.log( "deleting index: "+ Object.values(this.editorSprite[this.frameIndex])[i][j]+ " in: " + Object.keys(this.editorSprite[this.frameIndex])[i] )
                this.editorSprite[this.frameIndex][Object.keys(this.editorSprite[this.frameIndex])[i]].splice(this.editorSprite[this.frameIndex][Object.keys(this.editorSprite[this.frameIndex])[i]].indexOf(Object.values(this.editorSprite[this.frameIndex])[i][j]), 1)  // deleting pixel if corresponding to index
                return [true, i, j]
              }
            }
          }
        }

      }
      // console.log("no colored pixel on this index")
    },
  }
}