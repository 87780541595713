export default {
  methods: {
    setSprite() {
      // this.editorSprite = this.importedSprite.frames

      if (this.importedSprite.background) {
        this.background = this.importedSprite.background
      }
      this.setAsBackground(this.background)

      this.setSizeSource()
      if (this.$route.params.id !== "blank") {
        // this.editorSprite = this.clearEmptyFramesAndLayers(this.hashHexKeys(this.importedSprite.frames))
        this.editorSprite = this.importedSprite.frames
        this.setGridOpacity()
      }
      else {
        this.editorSprite = (this.importedSprite.frames)
        this.gridOpacity = 0.10
        this.setGridOpacity()
      }
      this.spriteID = this.importedSprite.id
      this.name = this.importedSprite.name
      // color of color-picker is the first swatch of current palette
    },
    setScene() {
      this.editorPalette = this.spritePalette
      if (this.editorPalette[0]) {
        this.color = this.editorPalette[0]
      }

      this.applyColorsOfFrame()
      this.createLayersPreview()
      this.runSprite()
      this.updateEnabled = true
    },
  }
}