export default {
  methods: {
    makeActiveLayer(index) {
      this.layerIndex = index
    },
    async makeNewLayer() {
      if (Array.isArray(this.editorSprite[this.frameIndex])) { // if layered frame
        if (this.layerIndex == this.editorSprite[this.frameIndex].length - 1) { // if top layer is selected
          this.editorSprite[this.frameIndex].push({})
          this.layerIndex++
        }
        else {
          await this.editorSprite[this.frameIndex].splice(this.layerIndex + 1, 0, {})
          this.layerIndex++
        }
      }
      else { // if frame is object
        let current = _.cloneDeep(this.editorSprite[this.frameIndex])
        let newLayer = [current, {}]
        // console.log(newLayer)
        await this.editorSprite.splice(this.frameIndex, 1, newLayer)
        this.layerIndex++
      }
      // this.$forceUpdate()
      // this.resetFrame()
      // this.applyColorsOfFrame()
      // this.resetLayers()
      // this.createLayersPreview()
      this.updateRenders()
    },
    duplicateLayer() {
      // this.saveVersion()

      if (Array.isArray(this.editorSprite[this.frameIndex])) {
        let memo = _.cloneDeep(this.editorSprite[this.frameIndex][this.layerIndex])
        this.editorSprite[this.frameIndex].splice(this.layerIndex + 1, 0, memo)
        this.layerIndex++

        // this.$forceUpdate()
        this.updateRenders()
      }
      // else {
      //   // layer is object
      //   let current = _.cloneDeep(this.editorSprite[this.frameIndex])
      //   let newLayer = [current, current]
      //   // console.log(newLayer)
      //   this.editorSprite.splice(this.frameIndex, 1, newLayer)
      //   this.layerIndex++

      //   // this.$forceUpdate()
      //   this.updateRenders()
      // }
    },
    deleteLayer() {
      // this.saveVersion()

      if (Array.isArray(this.editorSprite[this.frameIndex]) && this.editorSprite[this.frameIndex].length > 1) {
        // this.editorSprite[this.frameIndex].reverse().splice(this.layerIndex, 0, {})
        if (confirm(`Are you sure you want to delete layer ${this.layerIndex} in frame #${parseInt(this.frameIndex + 1)} ?`)) {
          if (this.layerIndex == this.editorSprite[this.frameIndex].length - 1) {
            let memoIndex = this.layerIndex - 1
            this.editorSprite[this.frameIndex].splice(this.layerIndex, 1)

            this.$toast(
              `spritebooth® : You deleted layer ${this.layerIndex} in frame #${parseInt(this.frameIndex + 1)}.`,
              {
                timeout: 8000,
                icon: 'fas fa-circle-user',
              }
            )

            this.layerIndex = memoIndex
            // this.resetFrame()
            // this.applyColorsOfFrame()
          }
          else if (this.layerIndex == 0) {
            let memoIndex = this.layerIndex
            this.editorSprite[this.frameIndex].splice(this.layerIndex, 1)
            this.$toast(
              `spritebooth® : You deleted layer ${this.layerIndex} in frame #${parseInt(this.frameIndex + 1)}.`,
              {
                timeout: 8000,
                icon: 'fas fa-circle-user',
              }
            )
            this.layerIndex = memoIndex
            // this.resetFrame()
            // this.applyColorsOfFrame()
          }
          else {
            let memoIndex = this.layerIndex
            this.editorSprite[this.frameIndex].splice(this.layerIndex, 1)

            this.$toast(
              `spritebooth® : You deleted layer ${this.layerIndex} in frame #${parseInt(this.frameIndex + 1)}.`,
              {
                timeout: 8000,
                icon: 'fas fa-circle-user',
              }
            )
            this.layerIndex = memoIndex - 1
            // this.resetFrame()
            // this.applyColorsOfFrame()
          }
        }
        this.updateRenders()
        return
      }
      else {
        this.$toast(
          `spritebooth® : The only layer of frame #${parseInt(this.frameIndex + 1)} cannot be deleted.`,
          {
            timeout: 8000,
            icon: 'fas fa-circle-user',
          }
        )
        return
      }
    },
    swapLayerDown(index) {
      let currentLayer = _.cloneDeep(this.editorSprite[this.frameIndex][index])
      let lowerLayer = _.cloneDeep(this.editorSprite[this.frameIndex][index - 1])

      if (index == 0) {
        this.$toast(
          `spritebooth® : layer #${this.layerIndex} is already at lowest level possible.`,
          {
            timeout: 8000,
            icon: 'fas fa-circle-user',
          }
        )
      }
      else {
        this.editorSprite[this.frameIndex].splice(index, 1, lowerLayer)
        this.editorSprite[this.frameIndex].splice(index - 1, 1, currentLayer)
      }
      this.updateRenders()
    },
    swapLayerUp(index) {
      let currentLayer = _.cloneDeep(this.editorSprite[this.frameIndex][index])
      let upperLayer = _.cloneDeep(this.editorSprite[this.frameIndex][index + 1])

      if (index == this.editorSprite[this.frameIndex].length - 1) {
        this.$toast(
          `spritebooth® : layer #${this.layerIndex} is already at highest level possible.`,
          {
            timeout: 8000,
            icon: 'fas fa-circle-user',
          }
        )
      }
      else {
        this.editorSprite[this.frameIndex].splice(index, 1, upperLayer)
        this.editorSprite[this.frameIndex].splice(index + 1, 1, currentLayer)
      }
      this.updateRenders()
    },
    ////////////////////// Clear All Empty Arrays of Frames ////////////////////////
    clearEmptyFramesAndLayers(sprite) {
      sprite = sprite.filter(x => x)
      for (let j = 0; j < sprite.length; j++) {
        if (Array.isArray(sprite[j])) {

          sprite[j] = sprite[j].filter(x => x).sort((a, b) => a - b)
          for (let layer of sprite[j]) {
            this.deleteEmptyArrays(layer)
          }
        }
        else {
          this.deleteEmptyArrays(sprite[j])
        }
      }
      return sprite
    },
    arryfyFrames(frames) {
      let res = []
      for (let frame of frames) {
        if (Array.isArray(frame)) {
          res.push(frame)
        }
        else {
          res.push([frame])
        }
      }
      return res
    }
  }
}
