import {HTML, ROOT} from '@/constants.js'
import { colord, extend } from "colord";
import a11yPlugin from "colord/plugins/a11y";

extend([a11yPlugin]);

export default {
  methods: {
    checkBackgroundColor(arg) {
      this.updateTheme(arg)
    },
    updateTheme(arg){
      let lumi = colord(`hsl(${arg})`).luminance()
      
      if (lumi > 0.5){
        // if background is a light tone
        HTML.classList.remove('--theme-dark')
        HTML.classList.add('--theme-light')
      }
      else{
        HTML.classList.remove('--theme-light')
        HTML.classList.add('--theme-dark')
        // if background is a dark tone back to default dark theme
      }
    },

  },
  mounted() {
  },
};
