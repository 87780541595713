import _ from "lodash"

export default {
  methods: {
    incFrameIndex() {
      return new Promise((res) => {
        if (this.frameIndex < this.numberOfFrames - 1) {
          this.frameIndex++
        }
        else {
          this.frameIndex = 0
        }
        res(this.frameIndex)
      })
        .then(() => this.updateRenders())
    },
    decFrameIndex() {
      return new Promise((res) => {
        if (this.frameIndex == 0) {
          this.frameIndex = this.numberOfFrames - 1
        }
        else {
          this.frameIndex--
        }
        res(this.frameIndex)
      })
        .then(() => this.updateRenders())
    },
    incCopyFrameIndex() {
      if (this.copyFrameIndex < this.numberOfFrames - 1) {
        this.copyFrameIndex++
        this.updateRenders()
      }
      else {
        this.copyFrameIndex = 0
        this.updateRenders()
      }
    },
    decCopyFrameIndex() {
      if (this.copyFrameIndex == 0) {
        this.copyFrameIndex = this.numberOfFrames - 1
        this.updateRenders()
      }
      else {
        this.copyFrameIndex--
        this.updateRenders()
      }
    },
    resetFrame() {
      for (let i = 0; i < this.$refs.pixel.length; i++) {
        this.$refs.pixel[i].style.backgroundColor = ''
      }
    },
    copyFrame() {
      if (confirm("Do you really want to COPY frame #" + (parseInt(this.copyFrameIndex + 1)) + " on frame #" + parseInt(this.frameIndex + 1) + " ?")) {
        if (Array.isArray(this.editorSprite[this.copyFrameIndex])) {
          let memo = _.cloneDeep(this.editorSprite[this.copyFrameIndex]) // cloning deep array
          this.editorSprite.splice(this.frameIndex, 1, memo)
        }
        // else {
        //   let deep = _.cloneDeep(this.editorSprite[this.copyFrameIndex])
        //   let blankObj = {}
        //   Object.assign(this.editorSprite[this.frameIndex], blankObj)
        //   Object.assign(this.editorSprite[this.frameIndex], deep)
        // }
      }
      this.updateRenders()
    },
    clearFrame() {
      if (confirm("Do you really want to clear all pixels of frame #" + (parseInt(this.frameIndex) + 1) + " ?")) {
        this.editorSprite.splice(this.frameIndex, 1, [{}])
        this.$toast(
          `You cleared all pixels of frame #${parseInt(this.frameIndex) + 1}`,
          {
            timeout: 8000,
            icon: 'fas fa-circle-user',
          }
        )
      }
      this.updateRenders()
    },
    deleteFrame() {
      if (confirm("Do you really want to DELETE frame #" + (parseInt(this.frameIndex) + 1) + " ?")) {
        this.$toast(
          `You deleted frame #${(parseInt(this.frameIndex) + 1)}`,
          {
            timeout: 8000,
            icon: 'fas fa-circle-user',
          }
        )

        this.editorSprite.splice(this.frameIndex, 1)
      }
      this.updateRenders()
    },
    addFrameBefore() {
      if (confirm('Do you really want to INSERT a frame BEFORE frame#' + (parseInt(this.frameIndex) + 1) + " ?")) {
        if (this.frameIndex == 0) {
          this.editorSprite.splice(0, 0, {})
          // this.decFrameIndex()
        }
        else {
          this.editorSprite.splice(this.frameIndex - 1, 0, {})
          this.decFrameIndex()
        }
      }
      this.updateRenders()
    },
    addFrameAfter() {
      if (confirm('Do you really want to INSERT a frame AFTER frame#' + (parseInt(this.frameIndex) + 1) + " ?")) {
        if (this.frameIndex == this.numberOfFrames) {
          this.editorSprite.splice(this.numberOfFrames - 1, 0, {})
          this.incFrameIndex()
        }
        else {
          this.editorSprite.splice(this.frameIndex + 1, 0, {})
          this.incFrameIndex()
        }
      }
      this.updateRenders()
    },
    moveFrameUp() { // this function moves only elements on current layer
      if (Array.isArray(this.editorSprite[this.frameIndex])) {
        for (let color in this.editorSprite[this.frameIndex][this.layerIndex]) {
          let arr = this.editorSprite[this.frameIndex][this.layerIndex][color].map(x => x - parseInt(this.size.cols)).slice(0)
          this.editorSprite[this.frameIndex][this.layerIndex][color] = arr
        }
        this.updateRenders()
      }
      else {
        for (let color in this.editorSprite[this.frameIndex]) {
          let arr = this.editorSprite[this.frameIndex][color].map(x => x - parseInt(this.size.cols)).slice(0)
          this.editorSprite[this.frameIndex][color] = arr
        }
        this.updateRenders()
      }
    },
    moveFrameDown() {
      if (Array.isArray(this.editorSprite[this.frameIndex])) {
        for (let color in this.editorSprite[this.frameIndex][this.layerIndex]) {
          let arr = this.editorSprite[this.frameIndex][this.layerIndex][color].map(index => index + parseInt(this.size.cols)).slice(0)
          this.editorSprite[this.frameIndex][this.layerIndex][color] = arr
        }
        this.updateRenders()
      }
      else {
        for (let color in this.editorSprite[this.frameIndex]) {
          let arr = this.editorSprite[this.frameIndex][color].map(index => index + parseInt(this.size.cols)).slice(0)
          this.editorSprite[this.frameIndex][color] = arr
        }
        this.updateRenders()
      }
    },
    moveFrameLeft() {
      if (Array.isArray(this.editorSprite[this.frameIndex])) {
        for (let color in this.editorSprite[this.frameIndex][this.layerIndex]) {
          let arr = this.editorSprite[this.frameIndex][this.layerIndex][color].map(index => index - 1).slice(0)
          this.editorSprite[this.frameIndex][this.layerIndex][color] = arr
        }
        this.updateRenders()
      }
      else {
        for (let color in this.editorSprite[this.frameIndex]) {
          let arr = this.editorSprite[this.frameIndex][color].map(index => index - 1).slice(0)
          this.editorSprite[this.frameIndex][color] = arr
        }
        this.updateRenders()
      }
    },
    moveFrameRight() {
      ///// move only current layer
      if (Array.isArray(this.editorSprite[this.frameIndex])) {
        for (let color in this.editorSprite[this.frameIndex][this.layerIndex]) {
          let arr = this.editorSprite[this.frameIndex][this.layerIndex][color].map(index => index + 1).slice(0)
          this.editorSprite[this.frameIndex][this.layerIndex][color] = arr
        }
        this.updateRenders()
      }
      else {
        for (let color in this.editorSprite[this.frameIndex]) {
          let arr = this.editorSprite[this.frameIndex][color].map(index => index + 1).slice(0)
          this.editorSprite[this.frameIndex][color] = arr
        }
        this.updateRenders()
      }
    },
    moveAllUp() {
      for (let frame of this.editorSprite) {
        if (Array.isArray(frame)) {
          for (let layer of frame) {
            for (let color in layer) {
              let arr = layer[color].map(index => index - parseInt(this.size.cols)).slice(0)
              layer[color] = arr
            }
            this.updateRenders()

          }
        }
        else {
          for (let color in frame) {
            let arr = frame[color].map(index => index - parseInt(this.size.cols)).slice(0)
            frame[color] = arr
          }
          this.updateRenders()
        }
      }
    },
    moveAllDown() {
      for (let frame of this.editorSprite) {
        if (Array.isArray(frame)) {
          for (let layer of frame) {
            for (let color in layer) {
              let arr = layer[color].map(index => index + parseInt(this.size.cols)).slice(0)
              layer[color] = arr
            }
            this.updateRenders()

          }
        }
        else {
          for (let color in frame) {
            let arr = frame[color].map(index => index + parseInt(this.size.cols)).slice(0)
            frame[color] = arr
          }
          this.updateRenders()
        }
      }
    },
    moveAllLeft() {
      for (let frame of this.editorSprite) {
        if (Array.isArray(frame)) {
          for (let layer of frame) {
            for (let color in layer) {
              let arr = layer[color].map(index => index - 1).slice(0)
              layer[color] = arr
            }
            this.updateRenders()

          }
        }
        else {
          for (let color in frame) {
            let arr = frame[color].map(index => index - 1).slice(0)
            frame[color] = arr
          }
          this.updateRenders()
        }
      }
    },
    moveAllRight() {
      for (let frame of this.editorSprite) {
        if (Array.isArray(frame)) {
          for (let layer of frame) {
            for (let color in layer) {
              let arr = layer[color].map(index => index + 1).slice(0)
              layer[color] = arr
            }
            this.updateRenders()

          }
        }
        else {
          for (let color in frame) {
            let arr = frame[color].map(index => index + 1).slice(0)
            frame[color] = arr
          }
          this.updateRenders()
        }
      }
    },
  },
  computed: {
    numberOfFrames() {
      return this.editorSprite.length
    },
    currentFrame() {
      return this.editorSprite[this.frameIndex]
      // output : object || array
    },
    currentFrameReverse() {
      if (Array.isArray(this.editorSprite[this.frameIndex])) {
        return _cloneDeep(this.editorSprite[this.frameIndex].reverse())
      }
      else {
        return ''
      }
    },
  }
}
