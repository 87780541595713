export default {
  methods: {
    colorPickModeOn(evnt) {
      if (evnt) {
        this.colorPickMode = true
      }
    },
    colorPickModeOff(evnt) {
      if (evnt) {
        this.colorPickMode = false
      }
    },
    turnDrawModeOn(evnt) {
      if (evnt) {
        this.drawMode = true
      }
    },
    turnDrawModeOff(evnt) {
      if (evnt) {
        this.drawMode = false
      }
    },
    turnEraseModeOn(evnt) {
      if (evnt) {
        this.eraseMode = true
      }
    },
    turnEraseModeOff(evnt) {
      if (evnt) {
        this.eraseMode = false
      }
    },
    // turnFillModeOn(evnt){
    //   if(evnt){
    //     this.fillMode = true
    //   }
    // },
    // turnFillModeOff(evnt){
    //   if(evnt){
    //     this.fillMode = false
    //   }
    // },
    turnMoveModeOn() {
      this.moveMove = true
      this.moveable.draggable = true
      this.moveable.resizable = true
      this.moveable.pinchable = true
    },
    turnMoveModeOff() {
      this.moveMove = false
      this.moveable.draggable = false
      this.moveable.resizable = false
      this.moveable.pinchable = false
    },
    turnPointerModesOff(evnt) {
      if (evnt) {
        this.turnDrawModeOff(evnt)
        this.turnEraseModeOff(evnt)
      }
    },
    setMode() {
      if (this.moveMode == false) {
        this.turnMoveModeOff()
      }
      else {
        this.turnMoveModeOn()
      }
    },
  }
}
