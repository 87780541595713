
import {ROOT} from '@/constants.js'
import {colord} from 'colord'

export default {
  methods: {
    dumpSelected() {
      let answer = 0
      for (let i = 0; i < this.editorSprite.length; i++) {
        if (this.editorSprite[i].hasOwnProperty(this.editorPalette[this.paletteIndex]) == false) {
          answer += 0
        }
        else {
          answer += 1
        }
      }
      if (answer == 0) {
        this.editorPalette.splice(this.paletteIndex, 1)
      }
      else {
        this.$toast(
          `Color exists in sprite. Thus can not be deleted.`,
          {
            timeout: 8000,
            icon: 'fas fa-circle-user',
          }
        )
      }
    },
    dumpUnused() {
      for (let i = 0; i < this.editorPalette.length; i++) {
        let presence = 0
        for (let j = 0; j < this.editorSprite.length; j++) {
          if (Array.isArray(this.editorSprite[j])) {
            for (let layer of this.editorSprite[j]) {
              this.deleteEmptyArrays(layer)
              if (layer.hasOwnProperty(this.editorPalette[i]) == true) {
                presence += 1
              }
            }
          }
          else {
            this.deleteEmptyArrays(this.editorSprite[j])
            if (this.editorSprite[j].hasOwnProperty(this.editorPalette[i]) == true) {
              presence += 1
            }
          }
        }
        if (presence == 0) {
          this.editorPalette.splice(i, 1)
          this.dumpUnused()
        }
      }
    },
    deleteEmptyArrays(obj) {
      for (let item in obj) {
        if (obj[item].length == 0) {
          delete obj[item]
        }
      }
    },
    addSwatch(color) {
      if (this.editorPalette.includes(color) == false) {
        this.editorPalette.push(color)
      }
    },
    selectSwatch(argIndex) {
      this.paletteIndex = argIndex
      this.color = this.editorPalette[argIndex]
    },
    replaceSelected() {
      let swatchSelected = this.editorPalette[this.paletteIndex]
      ///// in all frames
      for (let j = 0; j < this.editorSprite.length; j++) {
        if (Array.isArray(this.editorSprite[j])) {
          for (let layer of this.editorSprite[j]) {
            if (layer.hasOwnProperty(swatchSelected)) {
              if (swatchSelected !== this.color) {
                Object.defineProperty(layer, this.color, Object.getOwnPropertyDescriptor(layer, swatchSelected))
                delete layer[swatchSelected]
              }
              this.editorPalette.splice(this.paletteIndex, 1, this.color)
            }
          }
        }
        else {
          if (this.editorSprite[j].hasOwnProperty(swatchSelected)) {
            if (swatchSelected !== this.color) {
              Object.defineProperty(this.editorSprite[j], this.color, Object.getOwnPropertyDescriptor(this.editorSprite[j], swatchSelected))
              delete this.editorSprite[j][swatchSelected]
            }
            this.editorPalette.splice(this.paletteIndex, 1, this.color)
          }
        }
      }
      /// just in current frame
      // if(this.editorSprite[this.frameIndex].hasOwnProperty(swatchSelected)==true){
      //   if (swatchSelected !== this.color ) {
      //     Object.defineProperty(this.editorSprite[this.frameIndex], this.color, Object.getOwnPropertyDescriptor(this.editorSprite[this.frameIndex], swatchSelected))
      //     delete this.editorSprite[this.frameIndex][swatchSelected]
      //   }
      //   this.editorPalette.splice(this.paletteIndex, 1, this.color)
      // }

      // this.resetFrame()
      // this.applyColorsOfFrame()
      this.updateRenders()
    },
    setAsBackground(color) {
      let _color = colord(color).toHslString().replace('hsl', '').replace('(', '').replace(')', '')
      ROOT.style.setProperty('--spread', _color)
      // console.log(color)
      this.background = color
      this.checkBackgroundColor(_color)
    },
    getPixelColor(key, evnt) {
      if (evnt) {
        let rgbBackGround = this.$refs.pixel[key].style.backgroundColor
        rgbBackGround = rgbBackGround.replace("rgb", "").replace("(", "").replace(")", "").split(",")
        // console.log(rgbBackGround)
        let hexColor = this.rgbToHex(rgbBackGround)
        // console.log(hexColor)
        this.color = hexColor

        for (let i = 0; i < this.spritePalette.length; i++) {
          if (this.spritePalette[i] == hexColor) {
            this.paletteIndex = i
          }
        }
      }
    },
    rgbToHex(arr) {
      let rgb = arr[2] | (arr[1] << 8) | (arr[0] << 16);
      return '#' + (0x1000000 + rgb).toString(16).slice(1)
    },
  },
  computed: {
    spritePalette() {
      var palette = []
      for (let i = 0; i < this.editorSprite.length; i++) {
        if (Array.isArray(this.editorSprite[i])) {
          for (let layer of this.editorSprite[i]) {
            for (let j = 0; j < Object.keys(layer).length; j++) {
              if (palette.includes(Object.keys(layer)[j]) == false) {
                palette.push(Object.keys(layer)[j])
              }
            }
          }
        }
        // else if (!Array.isArray(this.editorSprite[i])) {
        //   for (let j = 0; j < Object.keys(this.editorSprite[i]).length; j++) {
        //     if (palette.includes(Object.keys(this.editorSprite[i])[j]) == false) {
        //       palette.push(Object.keys(this.editorSprite[i])[j])
        //     }
        //   }
        // }
      }
      return palette
    },
  }
}
