export default {
  methods: {
    currentColor(indexArg){
      return Object.keys(this.editorSprite[this.frameIndex])[indexArg]
    },
    renderFrame(frame) {
      for (let color of Object.keys(frame)) {
        for (let pixelIndex of frame[color]) {
          this.applyColorToPixel( pixelIndex, [color])
        }
      }
    },
    applyColorsOfFrame(){
      if( Array.isArray(this.editorSprite[this.frameIndex]) ) {
        for (let i=0; i< Object.keys(this.editorSprite[this.frameIndex]).length; i++){
          this.renderFrame(
            this.editorSprite[this.frameIndex][Object.keys(this.editorSprite[this.frameIndex])[i]]
          )
        }
        this.runSprite()
      }
      else {
        for (let j=0; j< Object.keys(this.editorSprite[this.frameIndex]).length; j++){ // number of color in frame
          let numberOfPixelsInColor = Object.values(this.currentFrame[Object.keys(this.currentFrame)[j]])
          for (let k=0; k< numberOfPixelsInColor.length; k++){ // number of pixel in color
            // filling background color with current map color
            this.applyColorToPixel( numberOfPixelsInColor[k], this.currentColor(j) )
          }
        }
        this.runSprite()
      }
    },
    applyColorToPixel(index, colorCode){
      if(index <= parseInt(this.size.cols) * parseInt(this.size.rows) - 1 && index >= 0){
        if(this.editorPalette && this.$refs.pixel){
          this.$refs.pixel[index].style.backgroundColor = colorCode
        }
      }
    },
    applyColorToLayerPixel(layerKey, pixelIndex, colorCode){
      if(pixelIndex <= parseInt(this.size.cols) * parseInt(this.size.rows) - 1 && pixelIndex >= 0){
        if(Array.isArray(this.editorSprite[this.frameIndex])){
          this.$refs.layerGrid[layerKey].children[pixelIndex].style.backgroundColor = colorCode
        }
        else {
          this.$refs.layerGridSingle.children[pixelIndex].style.backgroundColor = colorCode
        }
      }
    },
    renderLayerFrame(objectLayer, layerKey) {
      let keys= Object.keys(objectLayer)
      if(keys.length > 0 ){
        for (let color of keys) {
          for (let pixelIndex of objectLayer[color]) {
              this.applyColorToLayerPixel(layerKey, pixelIndex, color)
          }
        }
      }
    },
    resetLayers(){
      if(Array.isArray(this.editorSprite[this.frameIndex])){
        for (let i=0; i < this.$refs.layerGrid.length; i++){
          for (let j=0; j<this.$refs.layerGrid[i].children.length; j++){
            this.$refs.layerGrid[i].children[j].style.backgroundColor = ''
          }
        }
      }
      else {
        if( Object.keys(this.editorSprite[this.frameIndex]).length > 0 ){
          for (let j=0; j<this.$refs.layerGridSingle.children.length; j++){
            this.$refs.layerGridSingle.children[j].style.backgroundColor = ''
          }
        }
      }
    },
    createLayersPreview() {
      if(Array.isArray(this.editorSprite[this.frameIndex])) {
        for(let i=0; i<this.editorSprite[this.frameIndex].length; i++) {
            this.renderLayerFrame(this.editorSprite[this.frameIndex][i], i)
        }
      }
      else {
        this.renderLayerFrame(this.editorSprite[this.frameIndex], 0)
      }
    },
  }
}
