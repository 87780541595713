<template>
  <div class="sprite-editor">
    <div class="sprite-controls" :class="{'__closed': !editorOpened}">
      <div class="control-wrapper flex fa-cent fj-cent fd-col">
        <div class="controls-opener">
          <div class="round" @click="openEditor">
          </div>
        </div>
        <!-- ///////////////////////////////// frames /////////////////////////////////  -->
        <div class="controls-row-horz">
          <p class="title-separator no-margin">Frames</p>
        </div>
        <div class="controls-row-horz">
          <button type="button" class="w-180" @click="decFrameIndex">
            <i class="fa-lg fa-solid fa-backward-step"></i>
          </button>
          <button v-if="isPaused" class="w-180" @click="playPause">
            <i class="fa-lg fa-solid fa-play"></i>
          </button>
          <button v-else-if="isPaused === false" class="w-180" @click="playPause">
            <i class="fa-lg fa-solid fa-pause"></i>
          </button>
          <button type="button" class="w-180" @click="incFrameIndex">
            <i class="fa-lg fa-solid fa-forward-step"></i>
          </button>
        </div>
        <!-- <div class="controls-row-horz" v-if="USER"> -->
        <div class="controls-row-horz">
          <button type="button" class="w-180" v-on:click="addFrameBefore">Ins Before</button>
          <p class="sprite-info">{{frameIndex + 1}}</p>
          <button type="button" class="w-180" v-on:click="addFrameAfter">Ins After</button>
        </div>
        <!-- <div class="controls-row-horz" v-if="USER"> -->
        <div class="controls-row-horz">
          <button type="button" class="w-180" v-on:click="clearFrame">
            <i class="fa-lg fa-solid fa-eraser"></i>
            clear frame
          </button>
          <button type="button" class="w-180" v-on:click="deleteFrame">
            <i class="fa-lg fa-solid fa-square-xmark"></i>
            delete frame
          </button>
        </div>
        <!-- <div class="controls-row-horz" v-if="USER"> -->
        <div class="controls-row-horz">
          <button type="button" class="w-240" v-on:click="copyFrame">
            copy frame
          </button>
          <button type="button" class="w-180" @click="decCopyFrameIndex">
            <i class="fa-lg fa-solid fa-arrow-left"></i>
          </button>
          <p class="sprite-info w-180">{{copyFrameIndex + 1}}</p>
          <button type="button" class="w-180" @click="incCopyFrameIndex">
            <i class="fa-lg fa-solid fa-arrow-right"></i>
          </button>
        </div>
        <div class="controls-row-vert">
          <input class="sprite-speed" type="range" name="" value="" min="10" max="420" step="1" v-model="duration">
          <p class="sprite-info">fps: {{Math.floor(1000/duration)}}</p>
        </div>
        <!-- ///////////////////////////////// grid /////////////////////////////////  -->
        <div class="controls-row-horz">
          <p class="title-separator">Grid</p>
        </div>
        <div class="controls-row-vert">
          <div class="controls-row-horz">
            <p class="sprite-info">pixel size: {{pixelSize}}</p>
            <p class="sprite-info">{{size.cols}} * {{ size.rows}}</p>
          </div>
        </div>
        <div class="controls-row-vert">
          <input class="sprite-pixel-size" type="range" min="0" value="" max="0.35" step="0.01" v-model="gridOpacity" @change="setGridOpacity" @mousemove="setGridOpacity">
          <p class="sprite-info">grid opacity</p>
        </div>
        <div class="controls-row-vert">

        </div>
        <div class="controls-row-vert">
          <div class="controls-row-horz">
            <input type="checkbox" class="mode" name=""
            v-model="moveMode" id="move-mode"  @change="setMode">
            <label for="move-mode">move / resize</label>
          </div>
        </div>
        <!-- ///////////////////////////////// Cols & Rows /////////////////////////////////  -->
        <div class="controls-row-horz">
          <p class="title-separator">Expand</p>
          <p class="title-separator">Shrink</p>
        </div>
        <div class="controls-row-horz">
          <div class="cross-grid">
            <div class="cross-grid-item"></div>
            <div class="cross-grid-item" @click="extendGridTop">
              <i class="fa-lg fa-solid fa-angle-up"></i>
            </div>
            <div class="cross-grid-item"></div>
            <div class="cross-grid-item" @click="extendGridLeft">
              <i class="fa-lg fa-solid fa-angle-left"></i>
            </div>
            <div class="cross-grid-item">
              <i class="fa-lg fa-solid fa-maximize"></i>
            </div>
            <div class="cross-grid-item" @click="extendGridRight">
              <i class="fa-lg fa-solid fa-angle-right"></i>
            </div>
            <div class="cross-grid-item"></div>
            <div class="cross-grid-item" @click="extendGridBottom">
              <i class="fa-lg fa-solid fa-angle-down"></i>
            </div>
            <div class="cross-grid-item"></div>
          </div>
          <div class="cross-grid">
            <div class="cross-grid-item"></div>
            <div class="cross-grid-item" @click="shrinkGridTop">
              <i class="fa-lg fa-solid fa-angle-down"></i>
            </div>
            <div class="cross-grid-item"></div>
            <div class="cross-grid-item" @click="shrinkGridLeft">
              <i class="fa-lg fa-solid fa-angle-right"></i>
            </div>
            <div class="cross-grid-item">
              <i class="fa-lg fa-solid fa-minimize"></i>
            </div>
            <div class="cross-grid-item" @click="shrinkGridRight">
              <i class="fa-lg fa-solid fa-angle-left"></i>
            </div>
            <div class="cross-grid-item"></div>
            <div class="cross-grid-item" @click="shrinkGridBottom">
              <i class="fa-lg fa-solid fa-angle-up"></i>
            </div>
            <div class="cross-grid-item"></div>
          </div>
        </div>
        <!-- ///////////////////////////////// Move Pixels /////////////////////////////////  -->
        <!-- <div class="controls-row-horz" v-if="USER"> -->
        <div class="controls-row-horz">
          <p class="title-separator">Move pixels</p>
        </div>
        <!-- <div class="controls-row-horz" v-if="USER"> -->
        <div class="controls-row-horz">
          <div class="cross-grid">
            <div class="cross-grid-item"></div>
            <div class="cross-grid-item" @click="moveFrameUp">up</div>
            <div class="cross-grid-item"></div>
            <div class="cross-grid-item" @click="moveFrameLeft">left</div>
            <div class="cross-grid-item"></div>
            <div class="cross-grid-item" @click="moveFrameRight">right</div>
            <div class="cross-grid-item"></div>
            <div class="cross-grid-item" @click="moveFrameDown">down</div>
            <div class="cross-grid-item"></div>
          </div>
          <!-- <div class="controls-row-vert" v-if="USER"> -->
          <div class="controls-row-vert">
            <button type="button" name="button" @click="crop">Crop</button>
          </div>
        </div>
        <!-- ///////////////////////////////// Drawing helpers /////////////////////////////////  -->
        <!-- <div class="controls-row-horz" v-if="USER"> -->
        <div class="controls-row-horz">
          <p class="title-separator">Drawing helpers</p>
        </div>
        <div class="controls-row-vert">
          <div class="flex">
            <input type="checkbox" class="mode"
            v-model="fillMode" id="fill-mode"  >
            <label for="fill-mode">Fill Mode</label>
          </div>
          <div class="flex">
            <input type="radio" class="mode" value="texture-even"
            v-model="textureMode" id="texture-even">
            <label for="texture-even">Even</label>

            <input type="radio" class="mode" value="texture-free"
            v-model="textureMode" id="texture-free">
            <label for="texture-free">Free</label>

            <input type="radio" class="mode" value="texture-odd"
            v-model="textureMode" id="texture-odd">
            <label for="texture-odd">Odd</label>
          </div>
          <div class="flex">
            <input type="checkbox" class="mode"
            v-model="ModeAntiDouble" id="mode-anti-double"  >
            <label for="mode-anti-double">Anti-Double Mode</label>
          </div>
        </div>
        <!-- ///////////////////////////////// layers /////////////////////////////////  -->
        <div class="controls-row-horz">
          <p class="title-separator">Layers</p>
        </div>
        <div class="controls-row-vert">
          <!-- <div class="controls-row-horz" v-if="USER"> -->
          <div class="controls-row-horz">
            <p>Frame {{frameIndex+1}}</p>
            <button @click="makeNewLayer">new</button>
            <button @click="duplicateLayer">
              <i class="fa-lg fa-regular fa-clone"></i>
              duplicate
            </button>
            <button @click="deleteLayer">delete</button>
          </div>
          <div v-if="Array.isArray(currentFrame)" class="controls-row-horz">
            <div class="controls-row-vert layers">
              <div  class="layer-master"
              v-if="isPaused"
              v-for="(layers,layersKey) in currentFrame"
              :class="{'__selected-index': layersKey == layerIndex }"
              @click="makeActiveLayer(layersKey)"
              :key="'layer-'+layersKey">
                <div class="layer-img-preview" ref="layerGrid" :style="setLayerGridDims()">
                  <div class="layer-img-preview-pixel"
                  v-for="(previewPixel,previewPixelKey) in size.cols * size.rows"
                  :key="layersKey+'-'+previewPixelKey">
                  </div>
                </div>
                <div class="control-row-horz layers-inside">
                  <li class="layer-child">{{ layersKey }}</li>
                  <div class="layer-arrow-group" v-if="layersKey == layerIndex">
                    <div class="layer-arrow" @click="swapLayerDown(layerIndex)">&#8681;</div>
                    <div class="layer-arrow" @click="swapLayerUp(layerIndex)">&#8679;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="controls-row-horz">
            <div class="controls-row-vert">
              <div v-if="isPaused" class="layer-master __selected-index">
                <div class="layer-img-preview" ref="layerGridSingle" :style="setLayerGridDims()">
                  <div class="layer-img-preview-pixel" v-for="(previewPixel,previewSinglePixelKey) in size.cols * size.rows" :key="previewSinglePixelKey">
                  </div>
                </div>
                <li class="layer-child">{{ 0 }}</li>
              </div>
            </div>
          </div>
        </div>
        <!-- ///////////////////////////////// colors /////////////////////////////////  -->
        <div class="controls-row-horz">
          <p class="title-separator">Colors</p>
        </div>
        <div class="controls-picker">
          <color-picker v-model="color" class="color-picker"></color-picker>
          <!-- <chrome-picker v-model="color" /> -->
        </div>
        <!-- <div class="controls-row-vert">
          <div class="controls-row-horz ">
          <p>{{ parseInt(colorOpacity).toString(16)}}</p>
            <input type="range" v-model="colorOpacity" min="0" max="255" step="5">
          </div>
        </div> -->
        <div class="controls-row-vert">
          <input type="checkbox" name="" class="mode" id="replace-in-current-only"/>
          <label for="replace-in-current-only">Replace in current frame only</label>
        </div>
        <div class="controls-row-vert">
          <div class="controls-row-horz">
            <div class="color-item" :style="'background-color:'+color">
              <span class="swatch-info">{{color}}</span>
            </div>

            <button type="button" @click="replaceSelected">
              Replace selected
            </button>

            <button type="button" @click="addSwatch(color)">
              Add New
            </button>
          </div>
        </div>
        <div class="controls-row-horz">
          <div class="controls-row-horz">
            <button type="button" @click="setAsBackground(color)">Set as background</button>
            <div class="background-sample pointer" :style="'background-color:'+background"
            @click="color = background">
            </div>
          </div>
        </div>
        <div class="controls-rows-vert">
          <div class="controls-row-horz">
            <div class="color-grid">
              <div class="color-item" v-for="(swatch, swatchKey, swatchIndex) in editorPalette" :style="'background-color:'+swatch" @click="selectSwatch(swatchKey, swatchIndex)" :class="{'__selected': swatchKey == paletteIndex }" ref="swatches" :key="swatchKey">
                <span class="swatch-info">{{swatch}}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="controls-row-horz" v-if="USER"> -->
        <div class="controls-row-horz">
          <button type="button" @click="dumpUnused">
            <i class="fa-lg fa-solid fa-trash-can"></i>
            Clear Unused
          </button>
        </div>
        <!-- /////////////////////////////// Save ///////////////////////////////// -->
        <!-- <div class="controls-row-horz" v-if="USER"> -->
        <div class="controls-row-horz">
          <p class="title-separator">Save</p>
        </div>
        <!-- <div class="controls-row-horz" v-if="USER"> -->
        <div class="controls-row-horz">
          <input type="text" v-model="name"/>
        </div>
        <!-- <div class="controls-row-horz" v-if="USER"> -->
        <div class="controls-row-horz">
          <!-- <button type="button" @click="saveSprite()">Save Sprite</button> -->
          <button type="button" @click="fork()">Fork</button>
          <button type="button" @click="publish()">Save / Update</button>
          <!-- <button type="button">
            <a :download="nameOfFile" class="button-link" :href="JSencoded">Download</a>
          </button> -->
        </div>

        <!-- /////////////////////////////// URLS ALLOWED ///////////////////////////////// -->
        <div class="controls-row-horz">
          <p class="title-separator">URLs allowed</p>
        </div>
        <div class="controls-row-horz">
          <input type="text"/>
        </div>
        
        
        <!-- /////////////////////////////// Version Control ///////////////////////////////// -->
        <div class="controls-row-horz">
          <p class="title-separator">Version Control</p>
        </div>
        <!-- <div class="controls-row-horz" v-if="USER"> -->
        <div class="controls-row-horz">
          <button type="button" @click="goBackOneVersion">Undo</button>
          <button type="button" @click="goFowardOneVersion">Redo</button>
        </div>
      </div>
    </div>
    <div class="sprite-container">
      <Moveable
        class="moveable"
        v-bind="moveable"
        @drag="handleDrag"
        @resize="handleResize"
        @scale="handleScale"
        ref="moveableElement"
      >
        <div class="sprite-grid"
        ref="mainGrid"
        @mouseleave="turnPointerModesOff($event)"
        @mousedown="turnDrawModeOn($event)"
        @mouseup="turnDrawModeOff($event)"
        @keydown.alt.exact="turnEraseModeOn($event)"
        @keyup.alt.exact="turnEraseModeOff($event)"
        @keydown.shift.exact="colorPickModeOn($event)"
        @keyup.shift.exact="colorPickModeOff($event)"
        :class="{'--cursor-hand': moveMode}">
          <div class="sprite-grid-pixel sprite-grid-show"
          v-for="(pixel,pixelKey) in size.cols*size.rows" ref="pixel"
          @mousemove="addPixel(pixelKey, color)"
          @mousemove.alt="handleDraw(pixelKey, '')"
          @click.exact="handleDraw(pixelKey, color, $event)"
          @click.alt="addPixel(pixelKey, '', $event)"
          @click.shift="getPixelColor(pixelKey, $event)"
          :key="`${pixelKey}-pixel`">
          </div>
        </div>
      </Moveable>
    </div>
  </div>
</template>

<script>
import ColorPicker from 'vue-color-picker-wheel'
import { Chrome } from 'vue-color'
import _ from "lodash"
import Moveable from "vue-moveable"
import _movableActions from "@/mixins/_movableActions"
import _checkBackground from "@/mixins/_checkBackground"
import _modes from "@/mixins/_modes"
import _colorPaletteActions from "@/mixins/_colorPaletteActions"
import _framesActions from "@/mixins/_framesActions"
import _layersActions from "@/mixins/_layersActions"
// import _hashHexKeys from "@/mixins/_hashHexKeys"
import _gridActions from "@/mixins/_gridActions"
import _renderActions from "@/mixins/_renderActions"
import _saveActions from "@/mixins/_saveActions"

import {supabase} from "@/supabase.js"
import _initialize from '../mixins/_initialize';
import _draw from '../mixins/_draw';
import {ROOT, BODY} from '@/constants.js'

// var moveableElement = document.querySelector('.sprite-grid')

// to clear default styles

export default {
  name: 'SpriteEditor',
  mixins: [
    _checkBackground,
    _modes,
    // _hashHexKeys,
    _colorPaletteActions,
    _framesActions,
    _layersActions,
    _gridActions,
    _movableActions,
    _renderActions,
    _saveActions,
    _initialize,
    _draw,
  ],
  components: {
    'chrome-picker': Chrome,
    ColorPicker,
    Moveable,
  },
  data(){
    return{
      editorOpened: false,
      isPaused: true,
      updateEnabled: false,
      // --------------------- New/Save ---------------------
      name: "",
      background: '',
      author: '',
      isPublic: true,
      id: null,
      
      fork_is_allowed: true,
      forked_from: null,
      unique_key: null,
      urls_allowed: null,
      map_layer_name: null,
      map_latency : null,

      size: {
        cols: null,
        rows: null
      },
      // --------------------- frames ---------------------
      editorSprite: [],
      importedSprite: {},
      frameIndex: 0,
      layerIndex: 0,
      duration: 100,
      copyFrameIndex: 0,
      // --------------------- memo ----------------------
      ramSprite: null,
      // --------------------- modes ---------------------
      moveMode: false,
      colorPickMode: false,
      drawMode: false,
      eraseMode: false,
      moveMode: false,
      fillMode: false,
      textureMode: 'texture-free',
      ModeAntiDouble: false,
      // --------------------- grid ---------------------
      gridWidth: null,
      gridHeight: null,
      pixelSize: null,
      gridOpacity: 0,
      defaultWidth: 60,
      defaultHeight: 50,
      // --------------------- color ---------------------
      color: "",
      colorOpacity: 255,
      paletteIndex: 0,
      editorPalette: [],
      // --------------------- moveable ---------------------
      moveable: {
        draggable: true,
        resizable: true,
        pinchable: true,
        target: document.querySelector('.sprite-grid'),
        throttleDrag: 1,
        throttleResize: 1,
        keepRatio: true,
        scalable: false,
        throttleScale: 0.01,
        rotatable: false,
        origin: false,
        renderDirections : ["nw", "ne", "sw", "se", "n", "s", "e", "w"],
        edge: true,
        bounds: { left: 0, right: 400, top: 0, bottom: 400},
      },
      // --------------------- version control ---------------------
      version: [],
      versionIndex: 0
    }
  },
  computed: {
    colorHex(){
      if(parseInt(this.colorOpacity).toString(16)== 'ff'){
        return this.color
      }
      else{
        return this.color.concat(parseInt(this.colorOpacity).toString(16))
      }
    },
    pickerColor(){
      if(this.colorOpacity == "00"){
        return this.color + '00'
      }
      else if(this.colorOpacity == "ff") return this.color
      else return this.color + this.colorOpacity
    },
    blankSprite(){
        let newSprite = Object.assign({}, {
          ...this.$store.state.blank,
          size: {
            cols : this.$store.state.newTemplateSize.width,
            rows: this.$store.state.newTemplateSize.height
          },
        })
        return newSprite
    },
    USER() {
      return this.$store.getters.AuthUser
    },
    JSencoded(){
      return "data:text/js;charset=utf-8," + "const "+ this.spriteObject.name.split("-").join("_") + "= " + encodeURIComponent(JSON.stringify(this.spriteObject) + "\n export default " + this.spriteObject.name.split("-").join("_") )
    },
    spriteObject(){
      return {
        name: this.name,
        size: {
          rows: this.size.rows,
          cols: this.size.cols,
        },
        background: this.background,
        frames: this.editorSprite
      }
    },
    nameOfFile(){
      return this.spriteObject.name.replaceAll(" ","-") + ".js"
    },
  },
  methods: {
    runSprite(){
      if(this.isPaused== false){
        if(this.frameIndex < this.numberOfFrames-1){
          setTimeout( () =>{
            this.resetFrame()
            this.frameIndex ++
            this.applyColorsOfFrame()
          }, this.duration )
        }
        else{
          clearTimeout()
          setTimeout( () =>{
            this.resetFrame()
            this.frameIndex = 0
            this.applyColorsOfFrame()
          }, this.duration )
        }
      }
      else{
        clearTimeout()
      }
    },
    togglePause(){
      if(this.isPaused){
        this.isPaused = false
      }
      else{
        this.isPaused = true
      }
    },
    playPause(){
      this.togglePause()
      this.runSprite()
    },
    openEditor(){
      if(this.editorOpened){
        this.editorOpened = false
      }
      else{
        this.editorOpened = true
      }
    },
    
    updateRenders(){
      if(this.updateEnabled = true){
        if(Array.isArray(this.editorSprite[this.frameIndex])){
          if(this.isPaused){
            this.resetLayers()
            this.createLayersPreview()
          }
        }
        else {
          if(this.isPaused){
            this.resetLayers()
            this.createLayersPreview()
          }
        }
        if(this.isPaused){
          this.resetFrame()
          this.applyColorsOfFrame()
        }
      }
    },
    async getDBSpriteFromParam(){
      const { data, error } = await supabase
      .from('sprites')
      .select()
      .eq('unique_key', this.$route.params.unique_key)
      return data[0]
    },
  },
  async beforeMount(){
    ROOT.style.setProperty('--grid-width', 0)
    ROOT.style.setProperty('--grid-height', 0)
    ROOT.style.setProperty('--pixel-size', 1)
    ROOT.style.setProperty('--cols', 0)
    ROOT.style.setProperty('--rows', 0)
    
    supabase
      .from('sprites')
      .on('INSERT', (payload) => {
        console.log('on insert payload ===>', payload)
        if(payload.new.author === this.$store.getters.AuthUser.id){
          this.$router.push(`/editor/${payload.new.unique_key}`)
        }
        this.$forceUpdate()   
      })
      .subscribe()
  },
  mounted(){
    if(this.$route.params.unique_key !== "blank"){
      this.getDBSpriteFromParam()
      .then( (res) => {
        return new Promise ((resolve, reject) => {
          let obj = res
          obj.frames = JSON.parse(res.frames)
          obj.size = JSON.parse(res.size)
          resolve(obj)
        })
      })
      .then( (res) => {
          // console.log(res)
          this.importedSprite = res
      })
      .then( () =>{
          this.size = this.importedSprite.size
          
          if(this.importedSprite.background){
            this.background = this.importedSprite.background
          }
          this.setAsBackground(this.background)
          this.setSizeSource()
          this.setGridSize(this.size.cols, this.size.rows)

          this.editorSprite = this.clearEmptyFramesAndLayers(this.importedSprite.frames)
          this.setGridOpacity()
          
          this.id = this.importedSprite.id
          this.unique_key= this.importedSprite.unique_key
          
          this.name = this.importedSprite.name
          this.author = this.importedSprite.author
          this.public = this.importedSprite.public
          
          this.forked_from = this.importedSprite.forked_from
          this.likes = this.importedSprite.likes

          this.editorPalette = this.spritePalette
          if(this.editorPalette[0]){
            this.color = this.editorPalette[0]
          }

          this.gridWidth = this.$refs.mainGrid.clientWidth //+ 'px'
          this.gridHeight = this.$refs.mainGrid.clientHeight // + 'px'

          this.turnMoveModeOff()
      })
      .then(() => {
          this.pixelSize = this.$refs.pixel[0].clientWidth //+ 'px'
      })
      .then(() => {
          
          this.applyColorsOfFrame()
          this.createLayersPreview()
          this.runSprite()
          this.updateEnabled = true
          
          return
      })
    }

    // ----------------------- BLANK NEW ------------------------------
    else if(this.$route.params.unique_key ==="blank"){
      this.importedSprite = this.blankSprite
      this.size = this.importedSprite.size
      
      
      this.setSprite()
      
      if(this.importedSprite.background){
        this.background = this.importedSprite.background
      }
      this.setAsBackground(this.background)

      this.setSizeSource()
      this.setGridSize(this.size.cols, this.size.rows)
      
      this.editorSprite = (this.importedSprite.frames)
        this.gridOpacity = 0.10
        this.setGridOpacity()
        
      this.id = null
      this.name = this.importedSprite.name
      
      
      this.gridWidth = this.$refs.mainGrid.clientWidth //+ 'px'
      this.gridHeight = this.$refs.mainGrid.clientHeight // + 'px')
      this.pixelSize = this.$refs.pixel[0].clientWidth //+ 'px' 
      
      this.turnMoveModeOff()
      
      this.applyColorsOfFrame()
      this.createLayersPreview()
      this.runSprite()
      this.updateEnabled = true
      
      return
    }
    
    
  },
  beforeDestroy(){
    this.moveable.target = null
  }
}
</script>

<style>
.sprite-editor {
  position: relative;
  height: 100vh;
  width: 100%;
  left: 0; right: 0;
  top: 0; bottom: 0;
  color: var(--panel-color);
  overflow-x: hidden;
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}
.sprite-editor::-webkit-scrollbar {
  display: none;
}
.moveable{
  width: auto !important;
  height: auto !important;
}
.sprite-grid{
  display: grid;
  grid-template-columns: repeat(var(--cols), auto);
  grid-template-rows: repeat(var(--rows), auto);
  cursor: crosshair;
  user-select: none;
  width: var(--grid-width);
  height: var(--grid-height);
  box-sizing: border-box;
}
.moveable-line {
  background: none !important;
}
.moveable-control {
  background: none !important;
  border: 2px solid var(--color-o-spread) !important;
}
.sprite-grid-show{
  border: 1px solid var(--grid-lines);
}
.--cursor-cross {
  cursor: crosshair;
}
.pixel-index{
  font-size: 0.5rem;
  font-family: sans-serif;
  background-blend-mode: color;
  color: var(--color-o-spread);
}
.sprite-container{
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.sprite-controls {
  position: absolute;
  z-index: 100;
  right: 0;
  top: 0;
  width: 360px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 64px 24px 64px;
  font-size: 0.8rem;
  background-color: var(--panel-transp);
  color: var(--panel-color);
  transition: right var(--fast-trans);
}
.control-wrapper{
  position: relative;
  width: calc( 360px - 48px);
  height: 100%;
}
.sprite-controls a{
  color: var(--panel-color);
  font-size: 0.8rem;
  margin-bottom: 16px;
}
.sprite-controls input,
.sprite-controls button{
  color: var(--panel-color);
  transition: color var(--fast-trans), transform var(--fast-trans);
}
.sprite-controls input:hover,
.sprite-controls button:hover{
  color: var(--panel-hover);
  box-shadow: 0 0 0 1px var(--panel-hover);
}
.sprite-controls input[type="range"]:hover{
  box-shadow: 0 0 0 0 transparent;
}
.__closed{
  right: -360px;
}
.sprite-info{
  color: var(--panel-color);
  text-align: center;
  width: 320px;
  margin: 8px;
}
p.sprite-info {
  font-size: 0.8rem;
}
.w-180{
  width: 180px;
}
.w-240{
  width: 240px;
}
.sprite-speed{
  transform: rotate(180deg);
}
.sprite-pause{
  margin-bottom: 16px;
}
.color-picker{
  position: relative;
  margin-top: 24px;
}
.controls-opener{
  position: fixed;
  top: calc(100vh/2 - 32px);
  right: calc(-32px + 320px + 40px);
  transform: rotate(45deg) scale(0.52);
  transform-origin: center;
  transition: transform .24s ease-out;
  cursor: pointer;
  box-shadow: 0 0 0 2px var(--color-o-spread);
  border-radius: 8px;
  transition: .24s right ease, transform .32s ease;
  overflow: hidden;
  background-color: hsla(var(--spread), 0.15) ;
}

.sprite-controls.__closed .controls-opener {
  box-shadow: 0 0 0 1px var(--color-o-spread);
  transform: rotate(calc(360deg*2 + 45deg)) scale(1);
  color: var(--panel-hover);
  right: -32px;
}
.sprite-controls.__closed .controls-opener:hover {
  animation: shadows .32s linear infinite;
}
@keyframes shadows {
  from{box-shadow: 0 0 0 1px var(--over-bg), 0px 0 0 1px var(--over-bg)}
  to{box-shadow: 0 0 0 1px var(--panel-hover), -40px 40px 0 0 var(--panel-hover-a)}
}
.round{
  height: 64px;
  width: 64px;
  opacity: 0;
  /* border-radius: 50%; */
  background-color: transparent;
  /* clip-path: polygon(100% 0%, 0% 50%, 100% 100%); */
}
.controls-opener:hover .round{
  opacity: 0.8;
  animation: multicolor .32s infinite linear;
  animation-fill-mode: forwards;
}
@keyframes multicolor {
  0%{background-color: hsl(90, 100%, 50%)}
  25%{background-color: hsl(180, 100%, 50%)}
  50%{background-color: hsl(240, 100%, 50%)}
  75%{background-color: hsl(300, 100%, 50%)}
}

.color-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--palette-size), var(--palette-size)));
  grid-gap: 8px;
  margin: 16px auto;
  width: 320px;
  justify-items: stretch;
  align-items: stretch;
  justify-content: center;
}
.color-item {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: 4px;
  height: var(--palette-size);
  width: var(--palette-size);
  font-size: 0.8rem;
  cursor: pointer;
}
.color-item.__selected {
  box-shadow: 0 0 0 2px cyan;
}
.swatch-info {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: monospace;
  font-size: 0.6rem;
  width: 100%;
  position: relative;
  background-color: rgba(10, 10, 12, 0.85);
  border-radius: 2px;
}
.color-actions {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 8px 0;
  min-height: 32px;
}
.color-picker {
  margin: 24px auto;
}
.background-sample {
  height: 40px;
  width: 40px;
  border-radius: 2px;
}
.controls-row-horz{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 auto;
  padding: 8px 0;
  min-height: 40px;
}
.controls-row-vert{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
}
.--cursor-hand:hover {
  cursor: grab;
}
input[type="checkbox"].mode,
input[type="radio"].mode {
  position: absolute;
  left: -999999999px;
  display: none;
  transition: var(--fast-trans) color, var(--fast-trans) background-color;
}
input[type="checkbox"].mode + label,
input[type="radio"].mode + label {
  font-size: 0.7rem;
  padding: 10px 16px;
  color: var(--panel-color);
  width: auto;
  border-radius: 3px;
  box-shadow: 0 0 0 1px currentColor inset;
  margin: 0;
}
input[type="checkbox"].mode + label:hover,
input[type="radio"].mode + label:hover {
  color: var(--panel-hover);
  cursor: pointer;
}
input[type="checkbox"].mode:checked + label,
input[type="radio"].mode:checked + label {
  color: var(--panel-darkest);
  background-color: var(--panel-hover);
}

.layer-master {
  box-shadow: 0 0 0 1px var(--panel-color) inset;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  transition: var(--fast-trans) box-shadow;
  cursor: pointer;
}
.layer-img-preview {
  height: 64px;
  width: calc(64px);
  box-shadow: 0 0 0 1px var(--panel-color) inset;
  margin: 4px;
  display: grid;
  background-color: var(--color-bg);
}
.__selected-index .layer-img-preview {
  box-shadow: 0 0 0 1px cyan inset;
}
.layer-child {
  /* width: 100%; */
  text-align: left;
  list-style-type: none;
  margin-left: 16px;
  color: var(--panel-color);
}
.__selected-index.layer-master {
  box-shadow: 0 0 0 2px cyan inset;
}
.move-controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cross-grid {
  display: grid;
  justify-items: stretch;
  align-items: stretch;
  max-width: 240px;
  margin: 0 auto;
  grid-template-columns: repeat( 3, 1fr);
  grid-template-rows: repeat( 3, 1fr);
}
.cross-grid-item {
  width : 40px;
  height: 40px;
  box-shadow: 0 0 0 1px transparent;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.cross-grid-item:nth-child(even) {
  box-shadow: 0 0 0 1px var(--panel-color) inset;
  cursor: pointer;
  user-select: none;
}
.cross-grid-item:nth-child(even):active {
  background-color: var(--panel-color);
  color: var(--panel-darker);
}
.title-separator {
  width: 100%;
  text-align: left;
  margin-top: 24px;
  color: var(--panel-color);
  border-bottom: 1px solid var(--panel-dark);
}
.no-margin {
  margin-top: 0;
}
.controls-row-vert.layers {
  flex-direction: column-reverse;
}
.layer-arrow {
  box-shadow: 0 0 0 1px var(--panel-color);
  height: 24px;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.layer-arrow:hover {
  background-color: cyan;
  box-shadow: 0 0 0 1px cyan;
  color: var(--panel-dark);
}
.layer-arrow-group {
  display: flex;
  margin-right: 8px;
}
.control-row-horz.layers-inside {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:inherit;
}
</style>
