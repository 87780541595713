const root = document.querySelector(':root')

export default {
  methods: {
    handleDrag({ target, transform }) {
      // console.log("onDrag", transform)
      target.style.transform = transform
    },
    handleResize({ target, width, height }) {
      // console.log("onResize", width, height)
      if(this.size.cols < this.size.rows){
        target.style.height = `${height}px`
        root.style.setProperty('--grid-height', `${height}px`)
        this.gridHeight = height
        root.style.setProperty('--grid-width', 'calc( calc(var(--cols) * var(--grid-height)) / var(--rows) )')
        this.gridWidth = this.$refs.mainGrid.clientWidth // + 'px'
        this.pixelSize = this.$refs.pixel[0].clientWidth // + 'px'
      }
      else if(this.size.cols >= this.size.rows) {
        target.style.width = `${width}px`
        root.style.setProperty('--grid-width', `${width}px`)
        this.gridWidth = width
        root.style.setProperty('--grid-height', 'calc( calc(var(--rows) * var(--grid-width)) / var(--cols) )')
        this.gridHeight = this.$refs.mainGrid.clientHeight // + 'px'
        this.pixelSize = this.$refs.pixel[0].clientHeight // + 'px'
      }
    },
    handleScale({ target, transform }) {
      // console.log("onScale", transform);
      target.style.transform = transform;
    },
  }
}
