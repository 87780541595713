const root = document.querySelector(':root')

export default {
  root,
  methods: {
    indexToCoord(index, cols) {
      let y = Math.floor(index / parseInt(cols)) + 1
      let x = (index % parseInt(cols)) + 1
      return { x: x, y: y }
    },
    coordToIndex({ x, y, cols }) {
      let index = (y - 1) * cols + x - 1
      return index
    },
    setLayerGridDims() {
      let cols = parseInt(this.size.cols)
      let rows = parseInt(this.size.rows)

      if (cols < rows) {
        var res = `
          grid-template-columns: repeat(${cols}, 1fr);
          grid-template-rows: repeat(${rows}, 1fr);
          height: ${Math.round(64 * 90 / 100)}px;
          width: ${Math.round(cols * (64 * 90 / 100) / rows)}px;
          `
      }
      else if (cols >= rows) {
        var res = `
          grid-template-columns: repeat(${cols}, 1fr);
          grid-template-rows: repeat(${rows}, 1fr);
          height: ${Math.round(rows * (64 * 90 / 100) / cols)}px;
          width: ${Math.round(64 * 90 / 100)}px;
          `
      }
      return res
    },
    setSizeSource() {
      if (this.$route.params.id === "blank") {
        this.size = this.blankSprite.size
        // console.log('this.size = ', this.size)
      }
      else if (this.$route.params.id !== "blank") {
        this.size = this.importedSprite.size
        // console.log('this.size = ', this.size)
      }
    },
    setGridSize(argCols, argRows) {
      this.size.cols = parseInt(argCols)
      this.size.rows = parseInt(argRows)
      root.style.setProperty('--cols', argCols)
      root.style.setProperty('--rows', argRows)

      if (parseInt(argCols) < parseInt(argRows)) {
        // console.log('Orientation : portrait')
        root.style.setProperty('--grid-height', `${this.defaultHeight}vh`)
        root.style.setProperty('--pixel-size', `${(this.defaultHeight / parseInt(argRows))}vh`)
        // this.pixelSize = `${this.defaultHeight / parseInt(argRows)}vh`
        // console.log('this.pixelSize ====> ', this.pixelSize)
        root.style.setProperty('--grid-width', 'calc( calc(var(--cols) * var(--grid-height)) / var(--rows) )')
      }
      else if (parseInt(argCols) >= parseInt(argRows)) {
        // console.log('Orientation : landscape')
        root.style.setProperty('--grid-width', `${this.defaultWidth}vh`)
        root.style.setProperty('--pixel-size', `${this.defaultWidth / parseInt(argCols)}vh`)
        // this.pixelSize = `${this.defaultWidth / parseInt(argCols)}vh`
        // console.log('this.pixelSize ====> ', this.pixelSize)
        root.style.setProperty('--grid-height', 'calc( calc(var(--rows) * var(--grid-width)) / var(--cols) )')
      }
    },
    setGridOpacity() {
      root.style.setProperty('--grid-opacity', this.gridOpacity)
    },
    mapToCoord(sprite) {
      this.ramSprite = sprite
      for (let frame of this.ramSprite) {
        if (Array.isArray(frame)) {
          for (let i = 0; i < frame.length; i++) {
            for (let color in frame[i]) {
              let arr = frame[i][color].map(x => this.indexToCoord(x, parseInt(this.size.cols)))
              frame[i][color] = arr
            }
          }
        }
        else {
          for (let color in frame) {
            let arr = frame[color].map(x => this.indexToCoord(x, parseInt(this.size.cols)))
            frame[color] = arr
          }
        }
      }
      return this.ramSprite
    },
    unMapToIndex() {
      for (let frame of this.ramSprite) {
        if (Array.isArray(frame)) {
          for (let i = 0; i < frame.length; i++) {
            for (let color in frame[i]) {
              let arr = frame[i][color].map(coord => this.coordToIndex({ x: coord.x, y: coord.y, cols: parseInt(this.size.cols) }))
              frame[i][color] = arr
            }
          }
        }
        else {
          for (let color in frame) {
            let arr = frame[color].map(coord => this.coordToIndex({ x: coord.x, y: coord.y, cols: parseInt(this.size.cols) }))
            frame[color] = arr
          }
        }
      }
      this.editorSprite = this.ramSprite
      this.ramSprite = null
    },
    extendGridLeft() {
      this.ramSprite = this.mapToCoord(this.editorSprite)
      this.size.cols = this.size.cols + 1
      this.setGridSize(this.size.cols, this.size.rows)
      this.unMapToIndex()
      this.moveAllRight()

      this.setLayerGridDims()
      this.updateRenders()
    },
    extendGridRight() {
      this.ramSprite = this.mapToCoord(this.editorSprite)
      this.size.cols = this.size.cols + 1
      this.setGridSize(this.size.cols, this.size.rows)
      this.unMapToIndex()

      this.setLayerGridDims()
      this.updateRenders()
    },
    extendGridTop() {
      this.ramSprite = this.mapToCoord(this.editorSprite)
      this.size.rows = this.size.rows + 1
      this.setGridSize(this.size.cols, this.size.rows)
      this.unMapToIndex()
      this.moveAllDown()

      this.setLayerGridDims()
      this.updateRenders()
    },
    extendGridBottom() {
      this.ramSprite = this.mapToCoord(this.editorSprite)
      this.size.rows = this.size.rows + 1
      this.setGridSize(this.size.cols, this.size.rows)
      this.unMapToIndex()

      this.setLayerGridDims()
      this.updateRenders()
    },
    crop() {
      console.log(
        this.getAllIndexes()
      )
    },
    getAllIndexes() {
      let indexes = []
      for (let frame of this.editorSprite) {
        if (Array.isArray(frame)) {
          for (let i = 0; i < frame.length; i++) { // layer
            let colorKeys = Object.keys(frame[i])
            for (let j = 0; j < colorKeys.length; j++) {
              for (let k = 0; k < frame[i][colorKeys[j]].length; k++) {
                indexes.push(frame[i][colorKeys[j]][k])
              }
            }
          }
        }
        else { // frame is object of color keys
          let colorKeys = Object.keys(frame)
          for (let i = 0; i < colorKeys.length; i++) {
            for (let j = 0; j < frame[colorKeys[i]].length; j++) {
              indexes.push(frame[colorKeys[i]][j])
            }
          }
        }
      }
      let res = new Set(indexes)
      return Array.from(res)
    },
    shrinkGridTop() {
      let allCoords = this.getAllIndexes().map(x => this.indexToCoord(x, parseInt(this.size.cols)))
      if (allCoords.every(coord => coord.y > 1)) {
        this.ramSprite = this.mapToCoord(this.editorSprite)
        this.size.rows = this.size.rows - 1
        this.setGridSize(this.size.cols, this.size.rows)
        this.unMapToIndex()
        this.moveAllUp()

        this.setLayerGridDims()
        this.updateRenders()
      }
      else {
        alert('Cannot shrink from top, this sprite has content on top row')
      }
    },
    shrinkGridRight() {
      let allCoords = this.getAllIndexes().map(x => this.indexToCoord(x, parseInt(this.size.cols)))
      if (allCoords.every(coord => coord.y < parseInt(this.size.rows))) {
        this.ramSprite = this.mapToCoord(this.editorSprite)
        this.size.cols = this.size.cols - 1
        this.setGridSize(this.size.cols, this.size.rows)
        this.unMapToIndex()
        this.moveAllRight()

        this.setLayerGridDims()
        this.updateRenders()
      }
      else {
        alert('Cannot shrink from top, this sprite has content that would be cut and lost.')
      }
    },
    shrinkGridLeft() {
      let allCoords = this.getAllIndexes().map(x => this.indexToCoord(x, parseInt(this.size.cols)))
      if (allCoords.every(coord => coord.x > 1)) {
        this.ramSprite = this.mapToCoord(this.editorSprite)
        this.size.cols = this.size.cols - 1
        this.setGridSize(this.size.cols, this.size.rows)
        this.unMapToIndex()
        this.moveAllLeft()

        this.setLayerGridDims()
        this.updateRenders()
      }
      else {
        alert('Cannot shrink from left, this sprite has content that would be cut and lost.')
      }
    },
    shrinkGridRight() {
      let allCoords = this.getAllIndexes().map(x => this.indexToCoord(x, parseInt(this.size.cols)))
      if (allCoords.every(coord => coord.x < parseInt(this.size.cols))) {
        this.ramSprite = this.mapToCoord(this.editorSprite)
        this.size.cols = this.size.cols - 1
        this.setGridSize(this.size.cols, this.size.rows)
        this.unMapToIndex()

        this.setLayerGridDims()
        this.updateRenders()
      }
      else {
        alert('Cannot shrink from right, this sprite has content that would be cut and lost.')
      }
    },
    shrinkGridBottom() {
      let allCoords = this.getAllIndexes().map(x => this.indexToCoord(x, parseInt(this.size.cols)))
      if (allCoords.every(coord => coord.y < parseInt(this.size.rows))) {
        this.ramSprite = this.mapToCoord(this.editorSprite)
        this.size.rows = this.size.rows - 1
        this.setGridSize(this.size.cols, this.size.rows)
        this.unMapToIndex()

        this.setLayerGridDims()
        this.updateRenders()
      }
      else {
        alert('Cannot shrink from bottom, this sprite has content that would be cut and lost.')
      }
    },
  },
}
